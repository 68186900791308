<template>
  <div></div>
</template>
  <script>
export default {
  name:'redirect',
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      if (to.query) {
        vm.$router.push({
          path: from.path,
          query: {
            newsInfo: to.query.newsInfo
          }
        });
      } else {
        vm.$router.push({
          path: from.fullPath
        });
      }
    });
  }
};
</script>
  <style>
</style>